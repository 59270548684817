import React from "react"
import { Trans, useTranslation } from "react-i18next"

import cx from "classnames"
import { Container } from "../components/container"
import SEO from "../components/seo"

import { TermsForm } from "../components/forms/terms-form"
import { useModal } from "../components/modal2"
import styles from "../components/modal2/modal.module.css"

const TermsModalForm = () => (
  <>
    <h3 className={cx("text-center", styles.modalHeaderTitle)}>
      <Trans i18nKey="forms.complaintForm.title">
        Reklamační <b>formulář</b>
      </Trans>      
    </h3>
    <TermsForm btnText="Odeslat" />
  </>
)

const TermsPage = () => {
  const { t } = useTranslation();
  const { show, RenderModal } = useModal()

  return (
    <>
      <RenderModal className="modalForm">
        <TermsModalForm />
      </RenderModal>
      <SEO title="Allgemeine Geschäftsbedingungen und Konditionen" />
      <Container className="terms">
        <h1 className="text-center">{t("termsPage.title")}</h1>
        <p className="text-center">{t("termsPage.subtitle")}
        </p>

        <section>
            <h3 style={{ marginBottom: "20px" }}>I. Begriffsbestimmungen</h3>
            Für die Zwecke dieser Allgemeinen Geschäftsbedingungen haben die nachfolgenden Begriffe die folgende Bedeutung:
            <p>
              Nutritionpro GmbH mit Sitz in Dingolfinger Straße 15, 81673 München, HRB 275560, E-Mail: info@nutritionpro.de, Telefon: +49 89 37040076 (im Folgenden "der Anbieter")
            </p>
            <p>
              NutritionPro Plan erstellt ein Plan für gesunde Ernährung, und übernimmt die Zubereitung und Lieferung von täglichen Mahlzeiten entsprechend der Anzahl der vom Kunden ausgewählten und bestellten Tages-/Wochen-/Monatszyklen an den vom Kunden angegebenen Ort oder Abholstelle.
            </p>
            <p>
              Das tägliche Mahlzeitenset ist eine ausgewogene Mischung von Mahlzeiten für den ganzen Tag, die vom Anbieter an den Kunden geliefert wird. Das tägliche Mahlzeitenset wird individuell auf den Kunden zugeschnitten, um eine optimale Zufuhr von Makronährstoffen auf der Grundlage der Informationen aus dem vom Kunden ausgefüllten Anfangsfragebogen und der eingegebenen Daten zur Körperzusammensetzung zu gewährleisten. Die Mahlzeiten in jedem Tagessatz werden eindeutig bestellt, gekennzeichnet, verpackt und dem Kunden an den von ihm angegebenen Ort oder an die Abholstelle geliefert.
            </p>
            <p>
              Der Kunde ist die Person, die den NutritionPro Plan auf www.nutritionpro.de als Interessent für die angebotenen Dienstleistungen bestellt, die auf einer regelmäßigen Einnahme von ernährungsphysiologisch ausgewogenen und nahrhaften Lebensmitteln beruhen. Der Kunde verpflichtet sich, eine vereinbarte Gebühr für diese Dienstleistungen an den Anbieter zu zahlen (im Folgenden "Kunde" genannt).
            </p>
        </section>

        <section>
          <h3>{t("termsPage.subtitle2")}</h3>
          <ol>
              <li>
                Sie können den NutritionPro Plan auf folgende Weise bestellen:
              </li>
              <li>
                Telefonisch unter +49 89 37040076, wobei der Kaufvertrag nach der Bestätigung der Bestellung durch den Anbieter während des Telefonats zustande kommt.
              </li>
              <li>
                Per E-Mail an die unter www.nutritionpro.de angegebene E-Mail-Adresse, wobei der Kaufvertrag nach der Bestätigung der Bestellung durch den Anbieter per E-Mail, Telefon oder durch die Lieferung des NutritionPro Plans zustande kommt (im Falle einer wiederholten Lieferung des NutritionPro Plans, wenn der Kunde auf die E-Mail des Anbieters, in der er über das nahende Ende des Zeitraums der vorherigen Bestellung informiert wird, mit einer neuen Bestellung antworten).
              </li>
              <li>
                Über ein Online-Formular auf www.nutritionpro.de, wobei der Kaufvertrag durch die telefonische Bestätigung der Bestellung durch den Anbieter zustande kommt.
              </li>
              <li>
                Die Bestellung des NutritionPro Plans muss spätestens 3 Werktage vor dem Tag erfolgen, an dem der NutritionPro Plan geliefert werden soll. Jede Bestellung ist ein verbindliches Angebot zum Abschluss eines Kaufvertrages.
              </li>
              <li>
                Leidet der Kunde an einer Nahrungsmittelallergie, so hat er den Anbieter unverzüglich telefonisch zu informieren, wobei gleichzeitig Schritte zum Umgang mit dieser Situation vereinbart werden.
              </li>
          </ol>
        </section>

        <section>
          <h3>{t("termsPage.subtitle3")}</h3>
          <ol start="7">
              <li>
                Der Kunde ist verpflichtet, den Anbieter über Änderungen der Bestellung, wie insbesondere die Stornierung des NutritionPro Plans oder die Änderung des bestellten Plans, mindestens 3 Werktage vor dem Tag der Lieferung des NutritionPro Plans zu informieren. Änderungen, die der Kunde innerhalb einer kürzeren als der im vorstehenden Satz genannten Frist mitteilt, werden nicht akzeptiert, und der NutritionPro Plan wird ordnungsgemäß an den Kunden geliefert und der Kunde ist verpflichtet, das Entgelt für den gelieferten NutritionPro Plan in voller Höhe an den Anbieter zu zahlen.
              </li>
              <li>
                Ändert/storniert der Kunde die Bestellung während der Arbeitszeit des Kundensupports, d.h. Mo-Fr von 10 bis 12 Uhr und von 13 bis 18 Uhr unter +49 89 37040076 jedoch spätestens 3 Werktage vor der Lieferung (ohne den Tag der Lieferung, d.h. der Tag der Lieferung minus 3 ganze Werktage), wird der Anbieter diese geänderte/stornierte Bestellung ändern/stornieren.
              </li>
          </ol>
        </section>
        <section>
          <h3>{t("termsPage.subtitle4")}</h3>
          <ol start="9">
              <li>
                Der Anbieter verpflichtet sich, den NutritionPro Plan gemäß der Bestellung des Kunden vorzubereiten, den NutritionPro Plan auf eigene Kosten an den vom Kunden angegebenen Ort zu liefern und ihn dem Kunden gemäß Absatz 2 dieses Artikels zu übergeben.
              </li>
              <li>
                Der Fahrer, der den NutritionPro Plan ausliefert, übergibt dem Kunden, der vom Kunden benannten Person oder einer anderen Person, die an dem vom Kunden angegebenen Ort für die Übergabe des NutritionPro Plans anwesend ist, das tägliche Mahlzeitset. Das Risiko einer Beschädigung des NutritionPro Plans geht mit der Übergabe an den Kunden auf diesen über. Ist an dem vom Kunden angegebenen Lieferort keine Person anwesend, der der NutritionPro Plan übergeben werden kann, und ist der Kunde telefonisch nicht erreichbar, wird der NutritionPro Plan in unser Lager geliefert und der Kunde über die weiteren Schritte informiert.
              </li>
              <li>
                Das tägliche Mahlzeitset wird gekühlt geliefert und muss bis zum Verzehr kühl gehalten werden, d.h. bei einer maximalen Temperatur von 5°C. Der Anbieter haftet nicht für die Qualität des NutritionPro Plans, wenn die Lagerbedingungen nach der Übergabe an den Kunden nicht eingehalten werden.
              </li>
              <li>
                Die täglichen Mahlzeiten werden in der Zeit von 18 bis 20 und 20 bis 22 Uhr an den angegebenen Ort geliefert. Der Kunde ist sich bewusst, dass sich dieser Zeitraum in Ausnahmefällen aufgrund von Verkehrs- und anderen ähnlichen Ereignissen ändern kann. Der Kunde wird über eine solche Änderung telefonisch informiert.
              </li>
              <li>
                Wenn der Kunde die vereinbarte Zeit der Lieferung an den von ihm angegebenen Ort ändern muss, ist er verpflichtet, diese Änderung per E-Mail oder telefonisch unter +49 89 vor 14 Uhr am Tag der Lieferung des täglichen Essenspakets mitzuteilen.
              </li>
          </ol>
        </section>
        <section>
          <h3>{t("termsPage.subtitle5")}</h3>
          <ol>
              <li>
                Der Anbieter haftet nicht für Mängel, die durch: unbefugte Eingriffe, unsachgemäße Lagerung, unsachgemäße Behandlung, Verwendung der Ware für andere als die festgelegten Zwecke, Naturkatastrophen oder höhere Gewalt nach der Übergabe der Ware an den Kunden verursacht wurden.
              </li>
              <li>
                Bei der Übernahme der Ware ist der Käufer verpflichtet, die Ware auf eventuelle Mängel, die während des Transports entstanden sind, zu überprüfen. Im Falle der Feststellung von Mängeln ist der Käufer verpflichtet, die Ware nicht zu übernehmen und den Verkäufer unverzüglich unter info@nutritionpro.de durch{" "}
                <button onClick={show}>Ausfüllen des Formulars</button>.
              </li>
              <li>
                Der Käufer ist außerdem verpflichtet, die gelieferte Ware unverzüglich zu untersuchen, insbesondere die Menge und Art der gelieferten Ware. Dabei festgestellte Mängel sind unverzüglich zu berichten. Spätere Reklamationen werden nicht berücksichtigt.
              </li>
              <li>
                Bei der Bemängelung der Qualität von Mahlzeiten wird die reklamierte Ware vom Fahrer des Anbieters abgeholt. Bei der Reklamation der Qualität von Mahlzeiten dürfen nicht mehr als 10% der Portion fehlen. Wenn der Verkäufer die Reklamation anerkennt, wird sie durch den Ersatz mit einer anderen Mahlzeit auf dem gleichen Preisniveau oder durch die Rückerstattung des Kaufpreises bei der nächsten Bestellung gelöst.
              </li>
              <li>
                Der Kunde ist verpflichtet, die Berechtigung seiner Reklamation, die er mit dem Formular gemäß Absatz 2 einreicht, zu beweisen, indem er ein Foto beifügt, das den behaupteten Mangel dokumentiert.
              </li>
          </ol>
        </section>
        <section>
          <h3>{t("termsPage.subtitle6")}</h3>
          <ol>
              <li>
                Der Kunde verpflichtet sich, den bestellten NutritionPro Plan beim Anbieter am angegebenen Ort zu übernehmen und den Kaufpreis für seinen Plan ordnungsgemäß und pünktlich an den Anbieter zu zahlen.
              </li>
              <li>
                Der Anbieter wird dem Kunden den Kaufpreis durch Ausstellung einer Rechnung mit Fälligkeitsdatum innerhalb von 7 Tagen ab Rechnungsstellung in Rechnung stellen. Der Anbieter ist berechtigt, dem Kunden die Rechnung an dem Tag auszustellen, an dem der Kunde den NutritionPro Plan bestellt, und dem Kunden die Rechnung per E-Mail zuzusenden.
              </li>
              <li>
                Auf der Grundlage der ausgestellten Rechnung zahlt der Kunde den Kaufpreis an den Anbieter durch eine bargeldlose Überweisung auf das Bankkonto des Anbieters unter Verwendung eines in der Rechnung angegebenen variablen Symbols.
              </li>
              <li>
                Der Kaufpreis gilt mit seiner Gutschrift auf dem Konto des Anbieters als bezahlt.
              </li>
              <li>
                Im Falle des Verzugs mit der Zahlung des Kaufpreises kann der Anbieter vom Kunden Verzugszinsen verlangen.
              </li>
              <li>
                Im Falle des Verzugs mit der Zahlung des Kaufpreises kann der Anbieter die Lieferung des bestellten Plans verweigern, bis der Kaufpreis für den zuvor gelieferten NutritionPro-Plan vollständig bezahlt ist.
              </li>
          </ol>
        </section>
        <section>
          <h3>{t("termsPage.subtitle7")}</h3>
          <ol start="7">
            <li>
              Alle Bedingungen, die in diesem Vertrag nicht ausdrücklich geregelt sind, richten sich nach den allgemein verbindlichen Rechtsvorschriften der Deutschen Republik, insbesondere nach den Bestimmungen des Bürgerliches Gesetzbuch (BGB) § 312j Besondere Pflichten im elektronischen Geschäftsverkehr gegenüber Verbrauchern
            </li>
            <li>
              Diese verbindlichen Bedingungen sind ab dem Tag ihrer Veröffentlichung auf der Internetseite des Anbieters gültig und wirksam. Der Anbieter wird den Kunden im Voraus über Änderungen oder Ergänzungen dieser Bedingungen informieren. Sollte eine der Bestimmungen dieser Bedingungen ungültig oder unwirksam sein, so berührt dies nicht die Gültigkeit der Bedingungen im Ganzen. Der Anbieter verpflichtet sich, die ungültige oder unwirksame Bestimmung durch eine andere Bestimmung zu ersetzen und den Kunden hierüber zu informieren. Widerspricht der Kunde der Änderung nicht innerhalb von 30 Tagen ab dem Tag der Bekanntgabe, so wird davon ausgegangen, dass der Kunde mit der Änderung der Geschäftsbedingungen einverstanden ist.
            </li>
            <li>
              Die Annahme dieser Bedingungen durch den Kunden gilt als verbindlich für den Kunden und den Anbieter, und der Kunde hat keine Vorbehalte gegen den Inhalt des Vertrags und der Bedingungen.
            </li>
          </ol>
        </section>
      </Container>
    </>
  )
}

export default TermsPage
